<template>
  <div class="flex flex-col fixed w-full bg-white z-80 inset-y-0 transform md:w-6/12">
<!--    <div class="h-36 bg-gray-300 sm:h-80 w-full relative overflow-hidden flex items-end justify-start">-->
<!--      <div class="absolute from-black opacity-25 bg-gradient-to-t inset-0 flex items-end justify-start" />-->
<!--      <img-->
<!--        v-if="soloLinkValue?.restaurant?.cover_picture"-->
<!--        :src="soloLinkValue?.restaurant?.cover_picture"-->
<!--        :alt="`${soloLinkValue?.restaurant?.name} profile picture`"-->
<!--        class="w-full h-full object-cover">-->
<!--      <img-->
<!--        v-else-->
<!--        src="@/assets/restaurant-cover.jpg"-->
<!--        :alt="`${soloLinkValue?.restaurant?.name} profile picture`"-->
<!--        class="w-full h-full object-cover">-->
<!--      <p class="text-default-2xl font-medium text-white p-4 absolute bottom-0 left-0">-->
<!--        {{ soloLinkValue?.restaurant?.name ?? 'Cargando...' }}-->
<!--      </p>-->
<!--    </div>-->
    <div class="relative overflow-y-auto h-full pb-20">
      <div class="h-36 bg-gray-300 md:h-60 w-full relative overflow-hidden flex items-end justify-start">
        <div class="absolute from-black opacity-25 bg-gradient-to-t inset-0 flex items-end justify-start" />
        <img
          v-if="soloLinkValue?.restaurant?.profile_picture"
          :src="soloLinkValue?.restaurant?.profile_picture"
          :alt="`${soloLinkValue?.restaurant?.name} profile picture`"
          class="w-full h-full object-cover">
        <img
          v-else
          src="@/assets/restaurant-cover.jpg"
          :alt="`${soloLinkValue?.restaurant?.name} profile picture`"
          class="w-full h-full object-cover">
        <p class="text-default-2xl font-medium text-white p-4 absolute bottom-0 left-0">
          {{ soloLinkValue?.restaurant?.name ?? 'Cargando...' }}
        </p>
      </div>
      <div id="booking-container" class="mx-auto">
        <booking
          solo-link
          :restaurant="soloLinkValue.restaurant"
          @validated-date="setReservationValues"
          @invalid-date="$emit('invalid-date', $event)"
          @loading="$emit('loading', $event)"
          @booking-modal="showBookingModal = $event"
          @error-code="$emit('error-code', $event)"
          class="px-4" />
      </div>
      <div class="px-4 pb-20">
<!--        <p class="text-left text-sm">Los plazos de reserva se proporcionan en asociación con</p>-->
<!--        <p class="text-left text-sm">IMPORTANTES: Este restaurante no ha conectado reservas a Solofoodies. Reservar por otra vía e indicar en esta sección.</p>-->
<!--        <div class="align-middle justify-center text-left mt-3">-->
<!--          <img src="https://lh3.googleusercontent.com/D7PxbVLdrqkoWSupLh5MJBMTpQ386REzJgj0V7-tanXddX1lfMG-My_n7sybImiS" class="inline-block w-5 h-5">-->
<!--          <p class="inline-block text-left ml-2">CoverManager</p>-->
<!--        </div>-->
      </div>
    </div>
    <div id="button-container" class="fixed w-full bottom-0 border-t-2 py-3 bg-white z-80">
      <button
        :disabled="loadingBooking"
        :class="{ 'bottom-14': isAuthenticated, 'bottom-0': !isAuthenticated }"
        class="block mx-auto rounded-md w-11/12 bg-primary p-2 text-white disabled:text-dgray disabled:bg-gray"
        @click="showBookingModal = true">
        <a-loader v-if="loadingBooking" class="h-6 w-6 block mx-auto" :color="'text-white'" />
        <span v-else>Continuar</span>
      </button>
    </div>
  </div>
<!--    <div class="w-full" :class="{ 'bottom-14': isAuthenticated, 'bottom-0': !isAuthenticated }">-->
<!--      <div class="text-center mx-auto w-full bg-white py-2 mb-4">-->
<!--        <button-->
<!--          @click="showBookingModal = true" class="btn_send_primary bg-primary mt-4 disabled:bg-gray">-->
<!--          <a-loader v-if="loadingBooking" class="h-6 w-6 mx-auto block" :color="'text-white'" />-->
<!--          <span v-else>-->
<!--            <i class="icon-colaborate" />-->
<!--            Continuar-->
<!--          </span>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->

  <confirm-modal
    solo-link
    v-model:show-modal="showBookingModal"
    v-model:comment="comment"
    v-model:first_name="client.first_name"
    v-model:last_name="client.last_name"
    v-model:email="client.email"
    v-model:phone="client.phone"
    :restaurant="soloLinkValue.restaurant"
    :reservation-form="{
        date: bookingFormValues.date,
        people_amount: bookingFormValues.people_amount,
        zone: bookingFormValues.zone,
        comment: comment,
      }"
    :loading="loading"
    :loading-reservation="loadingBooking"
    :completed="false"
    @update-comment="comment = $event"
    @confirm-booking="$emit('confirm-booking', { ...bookingFormValues, ...client, comment: comment })"/>
</template>

<script>
import Booking from '@/components/booking/booking'
import { ref } from 'vue'
import ConfirmModal from '@/components/booking/confirm-modal'
import { useSession } from '@/hooks'

export default {
  name: 'booking-continer',
  components: { Booking, ConfirmModal },
  props: {
    soloLinkValue: { required: false, type: Object },
    loadingBooking: { required: false, type: Boolean },
    showModal: { required: false, type: Boolean, default: false }
  },
  emits: ['confirm-booking', 'invalid-date', 'loading', 'error-code'],
  setup (props) {
    const comment = ref('')
    const bookingFormValues = ref([])
    const bookingValues = ref(false)
    const loadingReservation = ref(false)
    const showBookingModal = ref(false)
    const loading = ref(false)
    const { isAuthenticated } = useSession()
    const client = ref({ first_name: '', last_name: '', email: '', phone: '' })
    const errorCode = ref('')

    const setReservationValues = (value) => {
      if (value) {
        bookingFormValues.value = (value)
        bookingValues.value = true
      } else {
        bookingValues.value = false
      }
    }

    // const handleShowModal = computed(() => {
    //   console.log(showBookingModal.value, props.showModal, props.soloLinkValue)
    //   console.log('lalalala')
    //   return props.showModal || showBookingModal.value
    // })
    //
    // onMounted(() => {
    //   console.log(props.showModal)
    // })

    return {
      comment,
      client,
      bookingFormValues,
      loadingReservation,
      showBookingModal,
      loading,
      isAuthenticated,
      errorCode,
      setReservationValues
    }
  }
}
</script>

<style scoped>
#booking-container{

}
#button-container{

}
</style>
