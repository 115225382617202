<template>
  <div v-if="loading" class="w-auto h-full display-block">
    <!--    <a-loader class="display-block h-16 w-16"/>-->
  </div>
  <div v-else class="flex flex-col items-center">
    <!-- testing -->
    <div
      v-if="soloLinkValue?.restaurant?.covermanager_id"
      class="flex flex-col w-full bg-white items-center z-80 md:min-w-booking">
      <booking-container
        :soloLinkValue="soloLinkValue"
        :loading-booking="loadingBooking"
        @invalid-date="invalidDate = $event"
        @confirm-booking="reservation"
        @loading="loadingBooking = $event"
      />
<!--      :disabled="invalidDate || loadingBooking || !bookingValues || bookingFail "-->
    </div>
    <div v-else>
      <h3 class="text-red text-center">
        No es posible crear reservas con este restaurante
      </h3>
    </div>
  </div>

  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="reserveError = false"
    :show="reserveError">
    <h2 class="text-lg text-red-400 text-center">
      Ha ocurrido un error
    </h2>
    <p class="text-normal text-center mt-4">
      No se ha podido crear la reserva.
    </p>
    <p class="text-lg text-center mt-4">
      {{ reserveErrorMessage }}
    </p>
  </a-alert>
  <a-alert
    hide-cancel
    disabled-overlay-dismiss
    @confirm="$router.push({ name: 'Login' })"
    :show="reserveSuccess">
    <h2 class="">
      ¡Enhorabuena!
    </h2>
    <p class="text-lg">
      Su reserva ha sido recibida. En breve recibirá confirmación vía email
    </p>
  </a-alert>

</template>

<script>
import { ref, onMounted, computed, reactive } from 'vue'
import { useArray, useRepository, useSession, useErrors } from '../../../hooks'
import { useRoute, useRouter } from 'vue-router'
// import Mask from 'primevue/inputmask'
// import BookingCard from '@/components/booking/booking-card'
import Moment from 'moment'
import BookingContainer from '@/components/booking/booking-container'

export default {
  components: {
    BookingContainer
    // Mask
    // BookingCard
  },
  data () {
    return {
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      today: '',
      startAt: '',
      modals: {
        reservation: false
      }
    }
  },
  setup () {
    const more = ref(false)
    const defaultLogo = require('@/assets/logo.png')
    // const $filters = inject('$filters', {})
    const values = ref({})
    const loading = ref(false)
    const modal = ref(false)
    const loadingBooking = ref(false)
    const bookingFail = ref(false)
    const reserveError = ref(false)
    const reserveErrorMessage = ref(false)
    const reserveSuccess = ref(false)
    const type = ref('')
    const selectedLink = ref(null)
    const getUserByIGUsername = useRepository(({ users }) => users.igUsername)
    const setReservation = useRepository(({ bookings }) => bookings.sololinks)
    const booking = useRepository(({ bookings }) => bookings)
    const soloLinks = useRepository(({ sololinks }) => sololinks)
    // const useErrors = useErrors()
    const { params } = useRoute()
    const router = useRouter()
    const soloLinkValue = ref({})
    const { isAuthenticated } = useSession()
    const [sololinks, { set: setSololinks, remove: removeSololink }] = useArray()
    const selectedHours = ref('empty')
    const selectedMinutes = ref('00')
    const bookingProvider = ref(false)
    const date = ref(null)
    const form = reactive({ comment: '' })
    const client = reactive({ first_name: '', last_name: '', email: '', phone: '' })
    const peopleAmount = ref(2)
    const people = ref(0)
    const peopleLength = ref(0)
    const checkBookingFail = ref(false)
    const bookingHours = ref([])
    const validDate = ref(false)

    const showModal = ref(false)

    const dateTest = ref([])
    const dateTestAux = ref([])
    const dateTestValue = ref(0)

    const providerZones = ref([])
    const zone = ref('')
    const zoneTime = ref('')

    const bookingFormValues = ref({})
    const bookingValues = ref(false)

    const fetch = () => {
      loading.value = !loading.value

      // soloLinks.showUUID(params.uuid)
      //   .then((response) => {
      //     soloLinkValue.value = response.data
      //     // if (selectedHours.value === 'empty') {
      //     //   booking.availableDates({ restaurant_id: soloLinkValue?.value?.restaurant?.id, people_count: 2 })
      //     //     .then(({ data: response }) => {
      //     //       dateTest.value = response?.available_dates
      //     //     })
      //     //     .catch((err) => (console.log(err)))
      //     //     .finally(() => (loadingBooking.value = false))
      //     // }
      //   })
      //   .catch((err) => (console.log(err)))
      soloLinks.search({ foodie_instagram: params.igusername, restaurant_instagram: params.restigusername })
        .then((response) => {
          soloLinkValue.value = response.data
        })
        .catch((err) => (console.log(err)))

      getUserByIGUsername(params.igusername)
        .then(({ data }) => {
          if (data.type !== 'foodie') {
            return router.push({ name: 'Login' })
          }

          values.value = data.object

          setSololinks(data.solo_links.sort((a, b) => a.created_at > b.created_at ? 1 : -1))
        })
        .catch(() => {
          router.push({ name: 'Login' })
        })
        .finally(() => (loading.value = !loading.value))
    }

    const checkReserve = (type) => {
      switch (type) {
        case 'date': {
          break
        }
        case 'hours': {
          break
        }
      }
      loadingBooking.value = true
      bookingFail.value = false
      peopleAmount.value = parseInt(peopleAmount.value)

      const peopleAmountFix = peopleAmount.value

      // peopleAmountFix = peopleAmountFix + 1

      const body = {
        restaurant_id: soloLinkValue?.value?.restaurant?.id,
        date: date.value + ' ' + selectedHours.value,
        people_amount: peopleAmountFix
      }
      if (zone.value) {
        body.cm_zone = zone.value
      }

      booking.verifyAvailability(body)
        .then(({ data }) => {
          people.value = Object.keys(data.provider_response.availability.people)
          peopleLength.value = people.value.length > 0 ? people.value.length : 2
          bookingHours.value = data.available_hours
          bookingFail.value = !data.available
          checkBookingFail.value = false
        })
        .catch(({ response }) => {
          bookingFail.value = true
          checkBookingFail.value = true
          reserveErrorMessage.value = ''
          reserveErrorMessage.value = useErrors(response?.data?.error_code)
          // switch (response?.data?.error_code) {
          //   case 'BK001': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK002': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK003': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK004': {
          //     reserveErrorMessage.value = 'Hora no disponible'
          //     break
          //   }
          //   case 'BK005': {
          //     reserveErrorMessage.value = 'No se pudo crear la reserva'
          //     break
          //   }
          //   case 'BK006': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK007': {
          //     reserveErrorMessage.value = 'Fecha no disponible, ya existe una reserva con este restaurante'
          //     break
          //   }
          //   case 'BK008': {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          //   case 'BK009': {
          //     reserveErrorMessage.value = 'Acción no disponible'
          //     break
          //   }
          //   case 'BK010': {
          //     reserveErrorMessage.value = 'Proveedor no disponible'
          //     break
          //   }
          //   default: {
          //     reserveErrorMessage.value = ''
          //     break
          //   }
          // }
        })
        .finally(() => (loadingBooking.value = false))
    }

    const reservation = (event) => {
      modal.value = true
      loadingBooking.value = true
      const newForm = {
        date: event.date,
        comment: event.comment,
        solo_link_id: soloLinkValue.value.id,
        use_booking_provider: true,
        people_amount: event.people_amount,
        zone: event.zone,
        client: {
          first_name: event.first_name,
          last_name: event.last_name,
          email: event.email,
          phone: '+34' + event.phone
        }
      }

      setReservation(soloLinkValue?.value?.id)
        .store({ ...newForm })
        .then(() => (reserveSuccess.value = true))
        .catch(({ response }) => {
          reserveError.value = true
          modal.value = false
          date.value = ''
          switch (response?.data?.error_code) {
            case 'BK001': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK002': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK003': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK004': {
              reserveErrorMessage.value = 'Las reservas no estan disponible para este restaurante'
              break
            }
            case 'BK005': {
              reserveErrorMessage.value = 'No se pudo crear la reserva'
              break
            }
            case 'BK006': {
              reserveErrorMessage.value = ''
              break
            }
            case 'BK007': {
              reserveErrorMessage.value = 'Ya tienes unas reserva con este restaurante'
              break
            }
            case 'BK008': {
              reserveErrorMessage.value = ''
              break
            }
            default: {
              reserveErrorMessage.value = ''
              break
            }
          }
        })
        .finally(() => (loadingBooking.value = false))
    }

    const links = computed(() => (more.value ? sololinks.value : sololinks.value.slice(0, 4)))

    const redirect = () => {
      if (isAuthenticated) {
        router.push({ name: 'explore.show', params: { id: values.value.id } })
      } else {
        router.push({ name: 'Login', query: { collaboration_with: values.value.id } })
      }
    }

    const reserveModal = (sololink) => {
      if (!isAuthenticated) {
        modal.value = true
        //
        selectedLink.value = sololink
      }
    }
    const phoneFormatter = (value) => {
      console.log(value)
      //
      // return value.replace(/\D/g, '')
    }

    onMounted(() => {
      fetch()
    })
    const changedZone = (value) => {
      zone.value = value
    }

    const setReservationValues = (values) => {
      bookingFormValues.value = values
      bookingValues.value = true
    }

    return {
      defaultLogo,
      values,
      soloLinks,
      selectedLink,
      links,
      loading,
      modal,
      reserveError,
      reserveErrorMessage,
      reserveSuccess,
      type,
      more,
      people,
      isAuthenticated,
      booking,
      peopleLength,
      peopleAmount,
      client,
      form,
      selectedHours,
      selectedMinutes,
      date,
      bookingProvider,
      soloLinkValue,
      checkBookingFail,
      bookingHours,
      bookingFail,
      loadingBooking,
      dateTest,
      dateTestAux,
      dateTestValue,
      providerZones,
      zoneTime,
      zone,
      bookingValues,
      validDate,
      showModal,
      fetch,
      checkReserve,
      redirect,
      reservation,
      removeSololink,
      reserveModal,
      phoneFormatter,
      changedZone,
      setReservationValues
    }
  },
  mounted () {
    this.today = Moment(new Date().toISOString())
  },
  methods: {
    addPeople (value) {
      if (value === 'add') {
        this.peopleAmount += 1
      } else {
        if (this.peopleAmount > 1) {
          this.peopleAmount -= 1
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
input[id^='zoneValue']:checked + label{
  @apply bg-primary text-white border-none
}
.main_bg_gray{
  background: #FAFAFA;
  height: 100vh;
}
.btn_send_primary{
  display:inline-block;
  color:white;
  height: 44px;
  width: 255px;
  border-radius: 44px;
}

.colab_list{
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  //padding: 10px 10px 0px 10px;
  li{
    background: white;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    line-height: 58px;
    width: 100%;
    margin-bottom: 10px;
    .avatar_img{
      width: 48px;
      height: 48px;
    }
    b{
      width: calc(100% - 78px);
      display: inline-block;
      line-height: 1.1;
      vertical-align: middle;
      text-align: left;
      padding-left: 10px;
    }
    &:last-of-type{
      margin-bottom: 0px;
    }
  }
}
//#phone{
//  height: 48px !important;
//  border: 1px solid #d1d1d1;
//  border-radius: 6px !important;
//  padding: 0 10px;
//  line-height: 48px;
//  width: 100%;
//  display: inline-block;
//  vertical-align: middle;
//  text-align: left !important;
//  font-size: 14px !important;
//}
//.p-inputtext{
//  background-color: #fff !important;
//}
.p-inputmask{
  background-color: white !important;
  border: none;
  text-align: left !important;
  font-size: 16px !important;
  padding: 0 !important;
}
.p-inputmask:enabled:focus {
  /*border: none !important;*/
  box-shadow: 0 0 0 0 #23BB2D !important;
  border: none;
  border-radius: 0;
}
.p-inputtext{
  border-radius: 0 !important;
}
#phone-container{
  height: 48px;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  padding: 0 10px;
  line-height: 48px;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
}
</style>
